import React from 'react';
import { ReactForm } from 'reactform';
import { requiring_system_store } from 'systemstorage';
import { providing_nearest_form } from 'formprovider';
import { WithCurrentUser, UserRecordPropType } from 'withcurrentuser';
import { Redirect } from 'react-router-dom';
import { default_save_button } from 'reactform';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

class BaseLoginControl extends React.Component {
    static defaultProps = {
        'user': null,
        'onSave': null,
    };
    render() {
        const { user, storage, onSave, ...childProps } = this.props;
        return <div className="react-dialog-pop-up">
            <div className="react-dialog-body">
                <ReactForm
                    form_key="login"
                    title="Login"
                    use_dialog={false}
                    target={{
                        'username': (user && user.username) || "Not yet logged in",
                        'password': '',
                    }}
                    onSave={(evt) => (onSave && onSave())}
                    submitOnEnter={true}
                    autoFocus="username"
                    buttons={[
                        (self) => default_save_button(self, {
                            'name': 'Login',
                            'action_message': 'Login',
                            'busy_message': 'Logging In',
                            'title': 'Attempt login with entered authorization parameters',
                        }),
                    ]}
                    default_messages={{
                        'success': 'Login Successful',
                        'errors': 'Login Failed',
                    }}
                    {...childProps}
                />
            </div>
        </div>;
    }
}
const LoginControl = requiring_system_store(
    providing_nearest_form(
        WithCurrentUser(BaseLoginControl),
        'system_forms'
    )
);

class LoginView extends React.Component {
    static defaultProps = {
        'redirect_target': '/passport/dashboard/',
        form_details: null,
    };
    onSave = () => {
        this.setState({ 'redirect': true });
        localStorage.setItem('isUserLoggedIn', true)
    };
    redirect_target = () => {
        const { location, redirect_target } = this.props;
        return redirect_target;
    };
    state = {
        'redirect': false,
    };

    componentDidMount(){
        const isUserPresent = JSON.parse(localStorage.getItem('isUserLoggedIn'));
        if(isUserPresent){
            this.setState({ 'redirect': true });
        }
    }
    render() {
        if (this.state.redirect) {
            return <Redirect to={this.redirect_target()} />;
        }
        return <LoginControl
            form_details={this.props.form_details}
            onSave={this.onSave}
            {...this.Props}
        />;
    }
}
LoginView.propTypes = {
    user: UserRecordPropType,
    redirect_target: PropTypes.string,
};
export { LoginControl, LoginView };
export default withRouter(LoginView);
