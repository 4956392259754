import React from 'react';
import { BaseRoutes } from 'dash/baseappconfig';
import { PAGE_FOCUS } from 'storages';
import { ResolveTarget } from 'dash/resolvetarget';
// import DeployedConfigView from 'shogun/deployedconfig';
import BaseDashboard from 'shogun/basedashboard';

// Samurai-specific routes...
import { Q2AEditTable, service_group_specific_storage } from 'shogun/q2aedittable';
import ChannelPlansView from 'channelplans/channelplansview';
// import {config_storage, ConfigurationTable} from 'shogun/configurationtable';
import VCTImportView from 'shogun/vctimportview';
import SourceImportView from 'shogun/sourcemappingview';
import TuningView from 'shogun/tuningview';
import TuningEditor from 'shogun/tuningeditor';

// Shogun/monitor specific bits

import EngineeringView from 'shogun/engineeringview';
import CVECProxyLink from 'shogun/cvecproxylink';
import OfflineConfigVsbb from 'shogun/offlineconfig_vsbb';

// Generic views...
import dashboard_routes from 'commonroutes/dashboard';
import crud_routes from 'commonroutes/crud';
import devices_routes from 'commonroutes/devices';
import groups_routes from 'commonroutes/groups';
import services_routes from 'commonroutes/services';
import epgs_routes from 'commonroutes/epgs';
import firmwares_routes from 'commonroutes/firmwares';
import alerting_routes from 'commonroutes/alerting';
import clifford_routes from 'commonroutes/clifford';

import { ReactForm } from 'reactform';
// import TestView from 'testview';
// Side-effect registration...
import enable_vsbb_view from 'shogun/nodeview_vsbb';
import enable_clifford_view from 'shogun/nodeview_clifford';
import enable_dvis_view from 'shogun/nodeview_dvis';
import enable_mdmini_view from 'shogun/nodeview_mdmini';
import { enable_node_summary } from 'dash/charts/nodesummary';
import { enable_recent_logs } from 'dash/charts/recentlogs';
import FactoryCepraBridge from 'shogun/ceprabridgestatus';

enable_vsbb_view();
enable_recent_logs();
enable_clifford_view();
enable_dvis_view();
enable_mdmini_view();
enable_node_summary();

// Helper functions
import {
    render_type_and_title,
    basic_focus_title,
    generic_pagefocus_link_url,
} from 'commonroutes/helpers';
import EPGUploads from 'epguploads';

const PREFIX = '/m/';
const ROUTE_CONTEXT = { PREFIX };


const APP_ROUTES = BaseRoutes({
    root_url: PREFIX,
    login_redirect: `${PREFIX}`,
    system_skip: ['date', 'location', 'power', 'snmp', 'firmware', 'license-server'],
    factory_skip: ['factory-theme'],
    factory_append: [
        {
            'title': 'EPGFetch Datasources',
            'permission': 'factory',
            'get_summary': () => {
                return 'Summary of EPG Uploads';
            },
            'key': 'factory-datasources',
            'render_component': props => <EPGUploads />,
        },
        {
            'title': 'Cepra Bridge',
            'permission': 'factory',
            'get_summary': () => {
                return 'VSBB 100 Support Bridge';
            },
            'key': 'factory-ceprabridge',
            'render_component': props => <FactoryCepraBridge />,

        }
    ],
    system_route_skip: ['debugging', 'logview', 'network', 'os-status'],
    system_prepend: [
        {
            'title': 'Default Concentrators',
            'key': 'concentrators',
            'details': <span>
                Provides the default URLs for concentrators/bridges which will
                be used to connect to the monitoring server.
            </span>,
            'get_summary': system => {
                return 'Moo';
            },
            'render_component': props => (
                <ReactForm
                    target={props.system}
                    form_key={'SystemBridge'}
                    use_dialog={false}
                />
            ),
            'permission': 'config.concentrators'
        },
    ],
    routes_prepend: [
        ...dashboard_routes(ROUTE_CONTEXT),
        ...devices_routes(ROUTE_CONTEXT),
        ...groups_routes(ROUTE_CONTEXT),
        ...services_routes(ROUTE_CONTEXT),
        ...alerting_routes(ROUTE_CONTEXT),
        ...clifford_routes(ROUTE_CONTEXT),
        {
            'path': `${PREFIX}log-dash/:group(\\d+)`,
            'link_url': generic_pagefocus_link_url,
            'header': 'Logs',
            'title': 'Logs',
            'component': BaseDashboard,
            'renderer': (props) => {
                const { match } = props;
                const group = parseInt(match.params.group);
                const definition = {
                    "label": "Logs",
                    "slug": "logs",
                    "blocks": [
                        {
                            "__type__": "table",
                            "card": true,
                            "card_title": "Logs",
                            "key": "metric_log.table",
                            "metric": "metric_log",
                            "query_type": "stats",
                            "resource_specific": false,
                            "stream_specific": false,
                            "defaultSorting": [
                                {
                                    "id": "time",
                                    "desc": true
                                },
                                { "id": "level", "desc": true },
                            ],
                            "default_view_settings": {
                                "hiddenColumns": [
                                    "stream_id",
                                    "service_id"
                                ],
                                "expanded": true
                            },
                            "widgets": {
                                "level": "LevelColor"
                            }
                        }
                    ]
                };
                return <ResolveTarget __type__='Group' __pk__={group} storage='shogunconf_forms' as_focus={true} key={`logs-${group}`}>
                    <BaseDashboard
                        __type__='Group' __pk__={group} storage='shogunconf_forms'
                        label='Logs'
                        role='logs'
                        dashboard={{ definition: definition }}
                    />
                </ResolveTarget>;
            }
        },
        // {
        //     'path': `${PREFIX}edit-dashboard/:dashboard(\\d+)`,
        //     'link_url': `${PREFIX}list/Dashboard`,
        //     'exact': true,
        //     hideInNav: true,
        //     'header': 'Dashboard Editor',
        //     'title': 'Dashboard Editor',
        //     'component': DashboardEdit,
        //     'renderer': (props) => {
        //         const { match } = props;
        //         const dashboard = parseInt(match.params.dashboard);
        //         return <DashboardEdit __type__='Dashboard' __pk__={dashboard} storage='system_forms' />;
        //     }
        // },
        ...epgs_routes(ROUTE_CONTEXT),
        ...firmwares_routes(ROUTE_CONTEXT),
        ...crud_routes(ROUTE_CONTEXT),
        {
            'path': `${PREFIX}services/:servicegroup(\\d+)/q2a`, // Bug in react router, ARGH
            // 'path': /^[/]m[/]services[/](\d+)[/]([a-zA-Z0-9_-]+)[/]?$/,
            hideInNav: true,
            'link_url': `${PREFIX}services`,
            'header': 'Q2A Configs',
            'title': basic_focus_title(
                (params) => `Config Package #${params.servicegroup} Q2A Services`,
                (focus) => `${render_type_and_title(focus)} Q2A Services`,
            ),
            'component': Q2AEditTable,
            'renderer': props => {
                // Have to unpack by position due to the bug in react router..
                const servicegroup = parseInt(props.match.params.servicegroup);
                const type_key = 'q2a';
                const storage_props = service_group_specific_storage(servicegroup, type_key);
                return <Q2AEditTable
                    key='q2a-services-${servicegroup}-${type_key}'
                    service_group={servicegroup}
                    service_type={type_key}
                    storage_props={storage_props}
                />;
            }
        },
        // {
        //     'path': `${PREFIX}tuning/:tuning?`,
        //     'link_url': `${PREFIX}tuning/`,
        //     // 'hideInNav': true,
        //     'header': 'Tuning',
        //     'title': basic_focus_title(
        //         (params) => params.tuning ? `Tuning Table #${params.tuning}` : `Tuning Tables`,
        //         (target, params) => params.tuning ? `${target.name || 'Tuning Table #' + target.__pk__}` : `Tuning Tables`
        //     ),
        //     'component': TuningView,
        //     'renderer': (props) => {
        //         const { tuning } = props.match.params;
        //         if (tuning) {
        //             return <TuningEditor __type__='TuningTable' __pk__={tuning} />;
        //         } else {
        //             return <TuningView />;
        //         }
        //     }
        // },
        {
            'path': `${PREFIX}vct/:mapping(\\d+)`,
            'link_url': `${PREFIX}services/`,
            'header': 'RF Sources',
            'title': basic_focus_title(
                (params) => `VCT Import Sources #${params.mapping}`,
            ),
            'hideInNav': true,
            'component': VCTImportView,
            'renderer': (props) => {
                const { match } = props;
                return <VCTImportView
                    __type__='RFInputMapping'
                    __pk__={match.params.mapping}
                />;
            }
        },
        {
            'path': `${PREFIX}source-map/:mapping(\\d+)`,
            'link_url': `${PREFIX}services/`,
            'header': 'Available Source Mapping',
            'title': basic_focus_title(
                (params) => `Add Available Sources to: #${params.mapping}`,
                (focus) => `Add Available Sources to: ${render_type_and_title(focus)}`,
            ),
            'hideInNav': true,
            'component': SourceImportView,
            'renderer': (props) => {
                const { match } = props;
                return <SourceImportView
                    __type__='AnalogMapping'
                    __pk__={match.params.mapping}
                />;
            }
        },

        {
            'path': `${PREFIX}channelplans/`,
            'link_url': `${PREFIX}services/`,
            'header': 'Channel Plans',
            'title': 'Channel Plans',
            'hideInNav': true,
            'component': ChannelPlansView,
            'renderer': (props) => {
                const { match } = props;
                return <ChannelPlansView />;
            }
        },
        {
            'path': `${PREFIX}engineering/`,
            'link_url': `${PREFIX}engineering/`,
            'permission': 'config.maintain_cluster',
            'header': 'Engineering',
            'title': 'Engineering Tasks',
            'component': EngineeringView,
            'renderer': (props) => {
                return <EngineeringView />;
            }
        },
        {
            'path': `${PREFIX}vsbbconfig/`,
            'link_url': `${PREFIX}vsbbconfig/`,
            // 'permission': 'config.maintain_cluster',
            'header': 'VSBB Offline Config',
            'title': 'VSBB Offline Config',
            'component': OfflineConfigVsbb,
            'renderer': (props) => {
                return <OfflineConfigVsbb />;
            }
        },
        // {
        //     'path': `${PREFIX}testview/`,
        //     'link_url': `${PREFIX}testview/`,
        //     'permission': 'config.maintain_cluster',
        //     'header': 'GUI Test Sheet',
        //     'title': 'GUI Test Sheet',
        //     'component': TestView,
        //     'renderer': (props) => {
        //         return <TestView />;
        //     }
        // },
    ],
});

export { APP_ROUTES, PREFIX };
