import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiButton from 'dash/MuiButton';
import MuiTypography from 'dash/MuiTypography';
import LoginButton from 'dash/loginbutton';
import WithCurrentUser from 'withcurrentuser';
import icon_for_type from 'typeicon';
import { useCurrentUser } from 'withcurrentuser';
import { ReactForm } from 'reactform';

const styles = theme => ({
    username: {
        marginRight: theme.spacing(2),
        textTransform: 'initial',
    },
    NoUpppercase: {
        textTransform: 'initial',
    },
    logout: {
        textTransform: 'none',
        borderLeft: `1px solid ${theme.roles.divider}`
    },
});

const BaseUserName = (props) => {
    const {
        classes,
    } = props;
    const [editing, setEditing] = React.useState(false);
    const user = useCurrentUser();
    const has_user = user && user.id ? true : false;
    const stopEditing = React.useCallback(() => {
        setEditing(false);
    }, [user]);
    let form = null;
    if (editing && has_user) {

        form = <ReactForm
            target={user}
            storage='system_accounts_forms'
            onClose={stopEditing}
            onSave={stopEditing}
        />;

    }
    return <React.Fragment>
        {has_user && (
            <MuiButton
                className={classes.username}
                key='username'
                onClick={() => setEditing(!editing)}
                title="Edit/update your user record"
            >
                {icon_for_type('User')}
                {user.name}
            </MuiButton>
        )}
        {has_user && (
            <MuiButton
                key='logout'
                className={classes.logout}
                onClick={()=> localStorage.setItem('isUserLoggedIn', false)}
                href="/accounts/logout/"
            >
                Logout
            </MuiButton>
        )}
        {(!has_user) && <LoginButton key='login' />}
        {form}
    </React.Fragment>;
};

const UserName = withStyles(styles)(BaseUserName);

export default UserName;
export { UserName, BaseUserName };